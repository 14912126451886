<template>
  <div>
    <app-header></app-header>

    <div class="login">
      <div class="login-logo text-center">
        <img src="../../assets/images/logo.png" alt="" class="" />
        <p class="text-center">
          {{ $t("Activate your account at Home") }}
        </p>
      </div>
      <div class="text-center">
        <form method="post" @submit.prevent="add()">
          <div class="page">
            <div class="field">
              <label for="name">{{ $t("phone") }}</label>
              <InputText id="name" type="text" v-model="phone" disabled />
            </div>
            <div class="field">
              <label for="password">4 DIGIT CODE</label>
              <InputText id="name" type="text" v-model="varifyMessage" />
            </div>

            <a class="frogetPassword" @click="resend()" style="cursor: pointer">
              {{ $t("Resend") }}
            </a>

            <p
              style="
                color: #f59f00;
                font-size: 1rem;
                font-weight: bold;
                margin-bottom: 3rem;
              "
              v-if="countDown < 60"
            >
              اعاده الارسال
              <span style="color: #777">
                {{ countDown }}
              </span>
            </p>
            <button
              class="btn btn-primary btn-login"
              type="submit"
              :disabled="save"
            >
              {{ $t("confirmation") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../../layouts/header.vue";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      phone: null,
      varifyMessage: null,
      save: false,
      countDown: 60,
    };
  },

  methods: {
    resend() {
      if (this.phone) {
        this.countDown = 60;
        this.$http
          .post(`auth/resendCode`, {
            phone: this.phone,
          })
          .then(
            (response) => {
              setInterval(() => {
                if (this.countDown > 0) {
                  this.countDown = this.countDown - 1;
                }
              }, 1000);
              this.$toast.add({
                severity: "success",
                summary: this.$t("Done Successfully"),
                detail: this.$t("Resend Code Successfully"),
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: this.$t("There error"),
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
      }
    },
    add() {
      if (this.varifyMessage && this.phone) {
        this.save = true;
        this.$http
          .get(
            `auth/activateUser?phone=${this.phone}&varifyMessage=${this.varifyMessage}`
          )
          .then(
            (response) => {
              this.save = false;

              this.$router.push(
                `/activateUser?phone=${this.phone}&varifyMessage=${this.varifyMessage}`
              );
            },
            (err) => {
              this.save = false;
              this.$toast.add({
                severity: "error",
                summary: this.$t("There error"),
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$t("There error"),
          detail: this.$t("Please fill in the fields"),
          life: 3000,
        });
      }
    },
  },
  async created() {
    this.phone = String(this.$route.query.phone).replace(/\s/g, "");
    this.varifyMessage = this.$route.query.varifyMessage;
  },
};
</script>
<style></style>
